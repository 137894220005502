
/* eslint-disable no-undef */
import { functions } from "@/plugins/firebaseConfig";
import { Team, UserProfile, VMenu, Walker } from "@/types";
import { Gender, Route } from "@/types/enums";
import { getRouteColour } from "@/utils/colours";
import Vue from "vue";
import WalkerForm from "./WalkerForm.vue";
import {
  createMainCertificate,
  createMultipleMainCertificates,
} from "@/utils/certificate";
import { createGroupReport, createMedicalReport } from "@/utils/reports";
import { getTotalTime } from "@/utils/route";

enum WalkingStatus {
  FINISHED = "Finished",
  RETIRED = "Retired",
  WALKING = "Walking",
  NOT_STARTED = "Not Started",
}

interface Header {
  text: string;
  align?: string;
  value: string;
  adminOnly: boolean;
}

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Walker Number",
          align: "start",
          value: "walkerNumber",
          adminOnly: false,
        },
        {
          text: "First Name",
          align: "start",
          value: "firstName",
          adminOnly: false,
        },
        {
          text: "Last Name",
          value: "lastName",
          adminOnly: false,
        },
        {
          text: "Group",
          value: "group",
          adminOnly: true,
        },
        {
          text: "Age",
          value: "age",
          adminOnly: false,
        },
        {
          text: "Mobile Number",
          value: "mobileNumber",
          adminOnly: false,
        },
        {
          text: "Medical Information",
          value: "hasMedicalDetails",
          adminOnly: false,
        },
        {
          text: "Route",
          value: "route",
          adminOnly: false,
        },
        {
          text: "Status",
          value: "status",
          adminOnly: false,
        },
        {
          text: "Start Time",
          value: "startTime",
          adminOnly: false,
        },
        {
          text: "Finish Time",
          value: "finishTime",
          adminOnly: false,
        },
        {
          text: "Time Taken",
          value: "totalTime",
          adminOnly: false,
        },
        {
          text: "Verified?",
          value: "verified",
          adminOnly: false,
        },
        {
          text: "Group Contact",
          value: "contact",
          adminOnly: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          adminOnly: false,
        },
      ] as Header[],
      walkerForm: false,
      deleteDialog: false,
      currentWalker: {} as Walker,
      search: "",
      helpDialog: false,
      filterDialog: false,
      filters: {
        route: ["Bronze", "Silver", "Gold"],
        verified: [true, false],
        status: Object.values(WalkingStatus),
      },
      filteredRoutes: [0, 1, 2],
      filteredVerifications: [0, 1],
      filteredStatuses: [0, 1, 2, 3],
      resendConfirmation: false,
      validateDialog: false,
      certificateDialog: false,
      ignorePrinted: true,
      timeDialog: false,
      startTime: "",
      finishTime: "",
      startTimeMenu: false,
      finishTimeMenu: false,
      bookingsClosedDialog: false,
      teamTimeDialog: false,
      emailDialog: false,
      editingLockedDialog: false,
      deletionLockedDialog: false,
      medicalInfoDialog: false,
      medicalDownloadDialog: false,
      deletionReason: "",
    };
  },
  computed: {
    walkers(): Walker[] {
      let walkerArray = this.$store.state.walkers.walkers as Walker[];
      let routeArray = this.filteredRoutes.map((v) => this.filters.route[v]);
      let verifiedArray = this.filteredVerifications.map(
        (v) => this.filters.verified[v]
      );
      let statusArray = this.filteredStatuses.map(
        (v) => this.filters.status[v]
      );
      walkerArray = walkerArray.filter((w) => {
        return (
          routeArray.includes(w.route) &&
          verifiedArray.includes(w.verified) &&
          statusArray.includes(this.getStatus(w))
        );
      });
      return walkerArray;
    },
    tableHeaders(): Header[] {
      const isAdmin = this.$store.getters["user/isAdmin"];
      return this.headers.filter((header) => !header.adminOnly || isAdmin);
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    startTimeMenuRef(): VMenu {
      return this.$refs.startTimeMenu as VMenu;
    },
    finishTimeMenuRef(): VMenu {
      return this.$refs.finishTimeMenu as VMenu;
    },
    bookingsOpened(): boolean {
      return (
        this.$store.getters["info/mainEventBookingsOpened"] ||
        this.$store.getters["user/isAdmin"]
      );
    },
    earlyBirdEnded(): boolean {
      return (
        this.$store.getters["info/mainEventEarlyBirdEnded"] &&
        !this.$store.getters["user/isAdmin"]
      );
    },
    bookingsClosed(): boolean {
      return (
        this.$store.getters["info/mainEventBookingsClosed"] &&
        !this.$store.getters["user/isAdmin"]
      );
    },
    bookingsOpeningDate(): Date {
      const date = (
        this.$store.state.info.dates.main
          .bookingsOpen as firebase.default.firestore.Timestamp
      ).toDate();
      return date;
    },
  },
  methods: {
    closeForm() {
      this.walkerForm = false;
    },
    newWalker() {
      if (this.bookingsClosed || !this.bookingsOpened) {
        this.bookingsClosedDialog = true;
      } else {
        this.currentWalker = {} as Walker;
        this.walkerForm = true;
      }
    },
    editWalker(walker: Walker) {
      if (this.bookingsClosed || !this.bookingsOpened) {
        this.bookingsClosedDialog = true;
      } else if (walker.verified && !this.isAdmin) {
        this.editingLockedDialog = true;
      } else {
        this.currentWalker = walker;
        this.walkerForm = true;
      }
    },
    deleteWalker(walker: Walker) {
      if (this.bookingsClosed || !this.bookingsOpened) {
        this.bookingsClosedDialog = true;
      } else if (this.earlyBirdEnded) {
        this.deletionLockedDialog = true;
      } else {
        this.currentWalker = walker;
        this.deleteDialog = true;
      }
    },
    confirmDelete() {
      this.$store
        .dispatch("walkers/deleteWalker", {
          walker: this.currentWalker,
          deletionReason: this.deletionReason,
        })
        .then(() => {
          this.deleteDialog = false;
          this.deletionReason = "";
        })
        .catch((err) => {
          throw err;
        });
    },
    getColour(route: Route) {
      return getRouteColour(route);
    },
    getStatus(walker: Walker): WalkingStatus {
      if (walker.finishTime) {
        return WalkingStatus.FINISHED;
      } else if (walker.retired) {
        return WalkingStatus.RETIRED;
      } else if (walker.startTime) {
        return WalkingStatus.WALKING;
      } else {
        return WalkingStatus.NOT_STARTED;
      }
    },
    getStatusColor(walker: Walker): string {
      const status = this.getStatus(walker);
      switch (status) {
        case "Finished":
          return "success";
        case "Retired":
          return "primary";
        case "Walking":
          return "warning";
        default:
          return "error";
      }
    },
    getContactName(id: string) {
      const contact = this.$store.getters["admin/getUser"](id) as UserProfile;
      return `${contact.firstName} ${contact.lastName}`;
    },
    resendValidationEmail(walker: Walker) {
      const resendEmail = functions.httpsCallable("resendValidationEmail");
      this.$store.commit("startLoading", "functions/resendValidationEmail");
      resendEmail({ id: walker.id })
        .then(() => {
          this.$store.commit(
            "finishLoading",
            "functions/resendValidationEmail"
          );
          this.resendConfirmation = true;
        })
        .catch((err) => {
          this.$store.commit(
            "finishLoading",
            "functions/resendValidationEmail"
          );
          this.$store.commit(
            "setErrorMessage",
            `Failed to resend email: ${err}`
          );
        });
    },
    sendValidationReminderEmails() {
      const ids = this.walkers
        .filter((walker) => !walker.verified)
        .map((walker) => walker.id);

      const sendEmails = functions.httpsCallable(
        "sendValidationReminderEmails"
      );
      this.$store.commit(
        "startLoading",
        "functions/sendValidationReminderEmails"
      );
      sendEmails(ids)
        .then(() => {
          this.$store.commit(
            "finishLoading",
            "functions/sendValidationReminderEmails"
          );
          this.emailDialog = false;
        })
        .catch((err) => {
          this.$store.commit(
            "finishLoading",
            "functions/sendValidationReminderEmails"
          );
          this.emailDialog = false;
          this.$store.commit(
            "setErrorMessage",
            `Failed to send emails: ${err}`
          );
        });
    },
    resendConfirmationEmails(walker: Walker) {
      if (!walker.verified) {
        this.$store.commit(
          "setErrorMessage",
          `Cannot send validation and kit list emails as this walker has not yet been validated.`
        );
        return;
      }
      const resendEmail = functions.httpsCallable(
        "resendConfirmationAndKitListEmails"
      );
      this.$store.commit(
        "startLoading",
        "functions/resendConfirmationAndKitListEmails"
      );
      resendEmail({ id: walker.id })
        .then(() => {
          this.$store.commit(
            "finishLoading",
            "functions/resendConfirmationAndKitListEmails"
          );
          this.resendConfirmation = true;
        })
        .catch((err) => {
          this.$store.commit(
            "finishLoading",
            "functions/resendConfirmationAndKitListEmails"
          );
          this.$store.commit(
            "setErrorMessage",
            `Failed to resend emails: ${err}`
          );
        });
    },
    openValidateDialog(walker: Walker) {
      this.currentWalker = walker;
      this.validateDialog = true;
    },
    validateWalker() {
      const walker = this.currentWalker;
      walker.verified = true;
      this.$store.dispatch("walkers/updateWalker", walker);
      this.validateDialog = false;
    },
    walkerIsInTeam(walker: Walker) {
      return this.$store.getters["teams/isInTeam"](walker.walkerNumber);
    },
    openMedicalInfoDialog(item: Walker) {
      this.currentWalker = item;
      this.medicalInfoDialog = true;
    },
    downloadWalkers() {
      const csvString = this.makeCsv(this.walkers);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "walkers.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeCsv(data: Walker[]) {
      const header =
        "Walker Number,Full Name,Group,First Name,Last Name,Email,Gender,Date of Birth,Age,Mobile Number,Membership Number,DBS Number,Medical Details,Help required in a medical incident,Address Line 1,Address Line 2,Town,County,Postcode,Group Contact";
      const values = data
        .map((o) => {
          const contact: UserProfile = o.contact
            ? this.$store.getters["admin/getUser"](o.contact)
            : {
                firstName: "",
                lastName: "",
                address: {
                  line1: "",
                  line2: "",
                  town: "",
                  county: "",
                  postcode: "",
                },
                phoneNumber: "",
              };
          return [
            `"${o.walkerNumber}"`,
            `"${o.firstName} ${o.lastName}"`,
            `"${o.group}"`,
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `"${o.email}"`,
            `"${o.gender}"`,
            `"${o.dob}"`,
            `"${o.age}"`,
            `="${o.mobileNumber}"`,
            `"${o.membershipNumber ?? ""}"`,
            `"${o.dbsNumber ?? ""}"`,
            `"${o.medicalDetails ?? ""}"`,
            `"${o.emergencyHelp ?? ""}"`,
            `"${o.homeContact.address.line1 ?? ""}"`,
            `"${o.homeContact.address.line2 ?? ""}"`,
            `"${o.homeContact.address.town ?? ""}"`,
            `"${o.homeContact.address.county ?? ""}"`,
            `"${o.homeContact.address.postcode ?? ""}"`,
            `"${contact.firstName} ${contact.lastName}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    exportToOldSystem() {
      const walkerLimit =
        this.$store.state.info.walkerNumbers.start.Gold +
        this.$store.state.info.walkerNumbers.limit.Gold;
      const exportList = [];

      for (let i = 1; i < walkerLimit; i++) {
        const walker = this.walkers.find((w) => w.walkerNumber === i);
        const team = (this.$store.state.teams.teams as Team[]).find(
          (t) => t.walkers.includes(i) || t.additionalWalkers.includes(i)
        );
        console.log(walker?.contact);
        const contact: UserProfile = walker?.contact
          ? this.$store.getters["admin/getUser"](walker?.contact)
          : {
              firstName: "",
              lastName: "",
              address: {
                line1: "",
                line2: "",
                town: "",
                county: "",
                postcode: "",
              },
              phoneNumber: "",
            };

        exportList.push({
          id: i,
          walkerNumber: i,
          route:
            i < this.$store.state.info.walkerNumbers.start.Silver
              ? Route.BRONZE
              : i < this.$store.state.info.walkerNumbers.start.Gold
              ? Route.SILVER
              : Route.GOLD,
          title: walker
            ? walker.gender === Gender.MALE
              ? "Mr"
              : walker.gender === Gender.FEMALE
              ? "Ms"
              : ""
            : "",
          first: walker ? walker.firstName : "",
          last: walker ? walker.lastName : "",
          nominatedTeam: this.$store.getters["teams/isCoreWalker"](i)
            ? "TRUE"
            : "FALSE",
          mobile: walker ? walker.mobileNumber : "",
          teamNumber: team ? team.teamNumber : "",
          startTime: walker ? walker.startTime : "",
          finishTime: walker ? walker.finishTime : "",
          walkTime: walker ? walker.totalTime : "",
          retiredCP: walker && walker.retired ? walker.lastCheckpoint : "",
          dob: walker ? walker.dob : "",
          age: walker ? walker.age : "",
          gender: walker
            ? walker.gender === Gender.MALE
              ? "M"
              : walker.gender === Gender.FEMALE
              ? "F"
              : ""
            : "",
          walkedBefore: "FALSE",
          status: walker
            ? walker.finishTime
              ? "F"
              : walker.retired
              ? "R"
              : walker.startTime
              ? "W"
              : "NS"
            : "NS",
          medicalDeclared: walker && walker.medicalDetails ? "TRUE" : "FALSE",
          group: walker ? walker.group : "",
          entryFormId: "",
          trophyAward: "",
          trophy2: "",
          trophy3: "",
          teamBreakup: team && team.isBroken ? "TRUE" : "FALSE",
          certPrinted: walker && walker.certificatePrinted ? "TRUE" : "FALSE",
          county: walker && !walker.outOfCounty ? "TRUE" : "FALSE",
          correspName: `${contact.firstName} ${contact.lastName}`,
          homeTel: "",
          corresMobile: contact.phoneNumber,
          address: [
            contact.address.line1,
            contact.address.line2,
            contact.address.town,
            contact.address.county,
            contact.address.postcode,
          ]
            .filter((v) => v !== "")
            .join(", "),
        });
      }

      const header =
        "Walkers ID,Walkers Number,Route,Title,First,Last,Nominated Team,Mobile,Team Number,Start Time,Finish Time,Walk Time,Retired CP,Dob,Age,Gender,Walked Before,Medical Declared,Group,Status,Entry Form ID,Trophy Award,Trophy2,Trophy3,Team Breakup,Cert Printed,County,Corresp Name,Hometel,Corres Mobile,Address";
      const body = exportList
        .map(
          (item) =>
            `="${item.id}",${item.walkerNumber},${item.route},${item.title},${item.first},${item.last},${item.nominatedTeam},="${item.mobile}",${item.teamNumber},${item.startTime},${item.finishTime},${item.walkTime},="${item.retiredCP}",${item.dob},${item.age},${item.gender},${item.walkedBefore},${item.medicalDeclared},="${item.group}",${item.status},${item.entryFormId},${item.trophyAward},${item.trophy2},${item.trophy3},${item.teamBreakup},${item.certPrinted},${item.county},${item.correspName},="${item.homeTel}",="${item.corresMobile}","=""${item.address}"""`
        )
        .join("\n");
      const csvString = `data:text/csv;charset=utf-8,${header}\n${body}`;
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "walkersExport.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCertificateCount(route: Route) {
      return this.walkers.filter(
        (w) => w.route === route && w.totalTime && !w.certificatePrinted
      ).length;
    },
    getRetiredCertificateCount() {
      return this.walkers.filter((w) => w.retired && !w.certificatePrinted)
        .length;
    },
    generateCertificate(walker: Walker) {
      this.$store.commit("startLoading", "walkers/generateCertificate");
      createMainCertificate(walker);
      this.$store.commit("finishLoading", "walkers/generateCertificate");
    },
    generateMultipleCertificates(route: Route) {
      this.$store.commit("startLoading", "walkers/generateCertificates");
      let walkers = this.walkers.filter(
        (w) => w.route === route && w.totalTime
      );
      if (this.ignorePrinted) {
        walkers = walkers.filter((w) => !w.certificatePrinted);
      }
      if (walkers.length === 0) {
        this.$store.commit("finishLoading", "walkers/generateCertificates");
        this.$store.commit("setErrorMessage", "No new certificates to print");
        return;
      }
      createMultipleMainCertificates(walkers);
      walkers.forEach((w) => {
        w.certificatePrinted = true;
        this.$store.dispatch("walkers/updateWalker", w);
      });
      this.$store.commit("finishLoading", "walkers/generateCertificates");
    },
    generateRetiredCertificates() {
      this.$store.commit("startLoading", "walkers/generateCertificates");
      let walkers = this.walkers.filter((w) => w.retired);
      if (this.ignorePrinted) {
        walkers = walkers.filter((w) => !w.certificatePrinted);
      }
      if (walkers.length === 0) {
        this.$store.commit("finishLoading", "walkers/generateCertificates");
        this.$store.commit("setErrorMessage", "No new certificates to print");
        return;
      }
      createMultipleMainCertificates(walkers);
      walkers.forEach((w) => {
        w.certificatePrinted = true;
        this.$store.dispatch("walkers/updateWalker", w);
      });
      this.$store.commit("finishLoading", "walkers/generateCertificates");
    },
    generateMedicalReport() {
      createMedicalReport(this.walkers.filter((w) => w.hasMedicalDetails));
    },
    downloadMedicalSpreasheet() {
      const csvString = this.makeMedicalSpreadsheet(
        this.walkers.filter((w) => w.hasMedicalDetails)
      );
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Walker Medical Information.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeMedicalSpreadsheet(data: Walker[]) {
      const header =
        "Walker Number,Medical Details,How to help in a medical incident";
      const values = data
        .map((o) => {
          return [
            `"${o.walkerNumber}"`,
            `"${o.medicalDetails ?? ""}"`,
            `"${o.emergencyHelp ?? ""}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    generateGroupReport() {
      createGroupReport(this.$store.state.admin.users, this.walkers);
    },
    openTimeDialog(walker: Walker) {
      this.currentWalker = walker;
      this.startTime = this.currentWalker.startTime;
      this.finishTime = this.currentWalker.finishTime;
      this.timeDialog = true;
    },
    saveWalkerTimes(updateTeam: boolean) {
      let totalTime = "";
      if (this.finishTime) {
        totalTime = getTotalTime(this.startTime, this.finishTime);
      }
      if (updateTeam) {
        const team: Team = this.$store.getters["teams/getTeamByWalker"](
          this.currentWalker.walkerNumber
        );
        team.startTime = this.startTime;
        team.finishTime = this.finishTime;
        team.totalTime = totalTime;
        this.$store
          .dispatch("teams/updateTeam", team)
          .then(() => {
            this.teamTimeDialog = false;
            this.timeDialog = false;
          })
          .catch((err) => {
            throw err;
          });
      } else {
        this.currentWalker.startTime = this.startTime;
        this.currentWalker.finishTime = this.finishTime;
        this.currentWalker.totalTime = totalTime;
        this.$store
          .dispatch("walkers/updateWalker", this.currentWalker)
          .then(() => {
            this.teamTimeDialog = false;
            this.timeDialog = false;
          })
          .catch((err) => {
            throw err;
          });
      }
    },
    resetFilters() {
      this.filteredRoutes = [0, 1, 2];
      this.filteredVerifications = [0, 1];
      this.filteredStatuses = [0, 1, 2, 3];
    },
    resetDialogScrollPosition() {
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
    },
  },
  watch: {
    walkerForm() {
      if (!this.walkerForm) {
        this.resetDialogScrollPosition();
      }
    },
  },
  created() {
    const routeArray = this.$route.query.route
      ? (JSON.parse(this.$route.query.route as string) as Route[])
      : Object.values(Route);
    this.filteredRoutes = routeArray.map((v) => this.filters.route.indexOf(v));
    const verifiedArray = this.$route.query.verified
      ? (JSON.parse(this.$route.query.verified as string) as boolean[])
      : [true, false];
    this.filteredVerifications = verifiedArray.map((v) =>
      this.filters.verified.indexOf(v)
    );
    const statusArray = this.$route.query.status
      ? (JSON.parse(this.$route.query.status as string) as WalkingStatus[])
      : Object.values(WalkingStatus);
    this.filteredStatuses = statusArray.map((v) =>
      this.filters.status.indexOf(v)
    );
  },
  components: {
    WalkerForm,
  },
});
