import { StaffProfile, Team, Trophy, UserProfile, Walker } from "@/types";
import { Route } from "@/types/enums";
import { jsPDF } from "jspdf";

export function createMedicalReport(walkers: Walker[]) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text("Walkers Medical Report", 297, 90, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("CONFIDENTIAL", 297, 115, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  // Generate header row
  generateHeader(doc, 140);

  let y = 160;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");

  walkers
    .filter((w) => w.hasMedicalDetails)
    .forEach((walker) => {
      const name = doc.splitTextToSize(
        `${walker.firstName} ${walker.lastName}`,
        95
      ) as string[];
      const group = doc.splitTextToSize(walker.group, 140) as string[];

      const splitMedicalDetails = doc.splitTextToSize(walker.medicalDetails, 200) as string[];
      const splitEmergencyHelp = doc.splitTextToSize(walker.emergencyHelp, 200) as string[]

      const medicalDetails = [
        'Medical info',
        ...splitMedicalDetails,
        '',
        'How to help',
        ...splitEmergencyHelp,
      ]

      const numberOfLines = Math.max(
        group.length,
        medicalDetails.length,
        name.length
      );

      const endY = y + (numberOfLines - 1) * 13 + 20;

      if (endY > 820) {
        doc.addPage();
        y = 30;
        generateHeader(doc, y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        y = 50;
      }

      generateRow(doc, 50, y, [
        {
          data: walker.walkerNumber.toString(),
          width: 25,
        },
        {
          data: name,
          width: 100,
        },
        {
          data: walker.age.toString(),
          width: 30,
        },
        {
          data: group,
          width: 150,
        },
      ]);

      doc.setFont('helvetica', 'bold');
      doc.text('Medical info', 355, y)
      doc.setFont('helvetica', 'normal');
      doc.text(splitMedicalDetails, 355, y + 13);
      doc.setFont('helvetica', 'bold');
      doc.text('How to help', 355, y + 13 * (2 + splitMedicalDetails.length));
      doc.setFont('helvetica', 'normal');
      doc.text(splitEmergencyHelp, 355, y + 13 * (3 + splitMedicalDetails.length));

      y += (numberOfLines - 1) * 13 + 5;
      generateHr(doc, y, 1);
      y += 15;
    });

  doc.save("Walker Medical Report.pdf");
}

export function createStaffMedicalReport(staff: StaffProfile[]) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text("Staff Medical Report", 297, 90, { align: "center" });
  doc.setFont("helvetica", "normal");
  doc.text("CONFIDENTIAL", 297, 115, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  // Generate header row
  generateStaffHeader(doc, 140);

  let y = 160;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");

  staff
    .filter((s) => s.hasMedicalDetails)
    .forEach((staff) => {
      const name = doc.splitTextToSize(
        `${staff.firstName} ${staff.lastName}`,
        140
      ) as string[];
      const team = doc.splitTextToSize(
        staff.checkpointNumber
          ? `${staff.subTeam} ${staff.checkpointNumber}`
          : staff.subTeam,
        120
      ) as string[];

      const splitMedicalDetails = doc.splitTextToSize(staff.medicalDetails, 220) as string[];
      const splitEmergencyHelp = doc.splitTextToSize(staff.emergencyHelp, 220) as string[]

      const medicalDetails = [
        'Medical info',
        ...splitMedicalDetails,
        '',
        'How to help',
        ...splitEmergencyHelp,
      ]

      const numberOfLines = Math.max(
        team.length,
        medicalDetails.length,
        name.length
      );

      const endY = y + (numberOfLines - 1) * 13 + 20;

      if (endY > 820) {
        doc.addPage();
        y = 30;
        generateStaffHeader(doc, y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
        y = 50;
      }

      generateRow(doc, 50, y, [
        {
          data: name,
          width: 150,
        },
        {
          data: team,
          width: 130,
        },
      ]);

      doc.setFont('helvetica', 'bold');
      doc.text('Medical info', 330, y)
      doc.setFont('helvetica', 'normal');
      doc.text(splitMedicalDetails, 330, y + 13);
      doc.setFont('helvetica', 'bold');
      doc.text('How to help', 330, y + 13 * (2 + splitMedicalDetails.length));
      doc.setFont('helvetica', 'normal');
      doc.text(splitEmergencyHelp, 330, y + 13 * (3 + splitMedicalDetails.length));

      y += (numberOfLines - 1) * 13 + 5;
      generateHr(doc, y, 1);
      y += 15;
    });

  doc.save("Staff Medical Report.pdf");
}

export function createGroupReport(users: UserProfile[], walkers: Walker[]) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text("Groups and Walkers List", 297, 90, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  const usersWithWalkers = users.filter(
    (u) => walkers.filter((w) => w.contact === u.id).length > 0
  );

  let y = 110;
  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");

  usersWithWalkers.forEach((user) => {
    const groupWalkers = walkers.filter((w) => w.contact === user.id);

    const endY = y + groupWalkers.length * 15 + 90;

    if (endY > 820) {
      doc.addPage();
      y = 30;
    }

    generateHr(doc, (y += 20), 3);
    generateHr(doc, (y += 7), 3);

    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text(user.group, 60, (y += 20));

    doc.setFontSize(14);
    doc.text(`${user.firstName} ${user.lastName}`, 60, (y += 20));

    doc.setFontSize(11);
    doc.setFont("helvetica", "italic");
    const addressString = [
      user.address.line1,
      user.address.line2,
      user.address.town,
      user.address.county,
      user.address.postcode,
    ]
      .filter((line) => line)
      .join(", ");
    doc.text(addressString, 60, (y += 12));

    y += 10;

    doc.setFont("helvetica", "normal");

    groupWalkers.forEach((w) => {
      y += 15;

      const status = w.finishTime
        ? "F"
        : !w.startTime
        ? "NS"
        : w.retired
        ? "R"
        : "-";

      generateRow(doc, 100, y, [
        {
          data: w.walkerNumber.toString(),
          width: 30,
        },
        {
          data: `${w.firstName} ${w.lastName}`,
          width: 150,
        },
        {
          data: status,
          width: 20,
        },
      ]);
    });
  });

  doc.save("Group Report.pdf");
}

export function createTrophyReport(
  bronzeTrophies: Trophy[],
  silverTrophies: Trophy[],
  goldTrophies: Trophy[],
  sobleTrophy: Trophy,
  walkers: Walker[]
) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text("Trophy Winners", 297, 90, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  let y = 110;
  generateTrophyHeader(doc, y);

  doc.setFontSize(16);
  generateHr(doc, (y += 5), 3);
  doc.text("Bronze", 50, (y += 20));

  y = generateTrophyTable(doc, y, bronzeTrophies, walkers);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  generateHr(doc, (y += 5), 3);
  doc.text("Silver", 50, (y += 18));

  y = generateTrophyTable(doc, y, silverTrophies, walkers);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  generateHr(doc, (y += 5), 3);
  doc.text("Gold", 50, (y += 18));

  y = generateTrophyTable(doc, y, goldTrophies, walkers);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  generateHr(doc, (y += 5), 3);
  doc.text("Other", 50, (y += 18));

  y = generateTrophyTable(doc, y, [sobleTrophy], walkers);

  doc.save("Trophies.pdf");
}

function generateTrophyHeader(doc: jsPDF, y: number) {
  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  generateRow(doc, 50, y, [
    {
      data: "Team No.",
      width: 40,
    },
    {
      data: "Walker No.",
      width: 45,
    },
    {
      data: "Walker",
      width: 120,
    },
    {
      data: "Gender",
      width: 45,
    },
    {
      data: "Age",
      width: 30,
    },
    {
      data: "Group",
      width: 130,
    },
    {
      data: "Start Time",
      width: 50,
    },
    {
      data: "Finish Time",
      width: 50,
    },
  ]);
}

function generateTrophyTable(
  doc: jsPDF,
  y: number,
  trophies: Trophy[],
  walkers: Walker[]
) {
  trophies.forEach((trophy) => {
    const endY = y + 6 * 21 + 32;

    if (endY > 820) {
      doc.addPage();
      y = 30;
      generateTrophyHeader(doc, y);
    }

    generateHr(doc, (y += 8), 3);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(
      `Walk Time: ${trophy.teams[0]?.totalTime ?? "--:--"}`,
      50,
      (y += 18)
    );
    doc.setTextColor("red");
    doc.text(`Winners of the ${trophy.name}`, 190, y);
    doc.setTextColor("black");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    y += 5;

    trophy.teams.forEach((team) =>
      team.walkers.forEach((walkerNumber) => {
        const walker = walkers.find((w) => w.walkerNumber === walkerNumber);
        if (walker) {
          generateHr(doc, (y += 7), 1);

          const name = doc.splitTextToSize(
            `${walker.firstName} ${walker.lastName}`,
            115
          ) as string[];
          const group = doc.splitTextToSize(walker.group, 125) as string[];

          generateRow(doc, 50, (y += 14), [
            {
              data: `${team.teamNumber}`,
              width: 40,
            },
            {
              data: `${walkerNumber}`,
              width: 45,
            },
            {
              data: name,
              width: 120,
            },
            {
              data: `${walker.gender}`,
              width: 45,
            },
            {
              data: `${walker.age}`,
              width: 30,
            },
            {
              data: group,
              width: 130,
            },
            {
              data: `${walker.startTime}`,
              width: 50,
            },
            {
              data: `${walker.finishTime}`,
              width: 50,
            },
          ]);

          const numberOfLines = Math.max(name.length, group.length);

          y += (numberOfLines - 1) * 14;
        }
      })
    );
  });

  return y;
}

export function createTeamResultsReport(
  teams: Team[],
  walkers: Walker[],
  trophies: Trophy[],
  route: Route
) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text(`${route} Team Result List`, 297, 90, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  let y = 110;
  generateTeamResultsHeader(doc, y);

  teams.forEach((team) => {
    const endY = y + 6 * 21 + 32;

    if (endY > 820) {
      doc.addPage();
      y = 30;
      generateTeamResultsHeader(doc, y);
    }

    const teamTrophies = trophies
      .filter((trophy) =>
        trophy.teams.some(
          (trophyTeam) => trophyTeam.teamNumber === team.teamNumber
        )
      )
      .map((trophy) => trophy.name);

    generateHr(doc, (y += 8), 3);
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(`${getPosition(team, teams)}`, 50, (y += 18));
    doc.text(`Walk Time: ${team.totalTime}`, 90, y);

    if (teamTrophies.length > 0) {
      doc.setTextColor("red");

      doc.text(`Winners of the ${teamTrophies.join(" and the ")}`, 230, y);
    }

    doc.setTextColor("black");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    y += 5;

    team.walkers.forEach((walkerNumber) => {
      const walker = walkers.find((w) => w.walkerNumber === walkerNumber);
      if (walker) {
        generateHr(doc, (y += 7), 1);

        const name = doc.splitTextToSize(
          `${walker.firstName} ${walker.lastName}`,
          115
        ) as string[];
        const group = doc.splitTextToSize(walker.group, 125) as string[];

        generateRow(doc, 90, (y += 14), [
          {
            data: `${team.teamNumber}`,
            width: 40,
          },
          {
            data: `${walkerNumber}`,
            width: 45,
          },
          {
            data: name,
            width: 120,
          },
          {
            data: `${walker.age}`,
            width: 45,
          },
          {
            data: group,
            width: 130,
          },
          {
            data: `${walker.startTime}`,
            width: 45,
          },
          {
            data: `${walker.finishTime}`,
            width: 45,
          },
        ]);

        const numberOfLines = Math.max(name.length, group.length);

        y += (numberOfLines - 1) * 14;
      }
    });
  });

  doc.save(`${route} Team Result Report.pdf`);
}

function generateTeamResultsHeader(doc: jsPDF, y: number) {
  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  generateRow(doc, 50, y, [
    {
      data: "Position",
      width: 40,
    },
    {
      data: "Team No.",
      width: 40,
    },
    {
      data: "Walker No.",
      width: 45,
    },
    {
      data: "Walker",
      width: 120,
    },
    {
      data: "Age",
      width: 45,
    },
    {
      data: "Group",
      width: 130,
    },
    {
      data: "Start Time",
      width: 45,
    },
    {
      data: "Finish Time",
      width: 45,
    },
  ]);
}

export function createIndividualResultsReport(walkers: Walker[], route: Route) {
  const doc = new jsPDF({ unit: "pt" });

  doc.setFont("helvetica", "normal");

  // Generate Title

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("Cotswold Marathon 2025", 297, 70, { align: "center" });
  doc.setFontSize(20);
  doc.text(`${route} Individual Result List`, 297, 90, { align: "center" });

  doc.setFont("helvetica", "normal");
  doc.setFontSize(11);
  doc.text("Date printed:", 450, 36);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(new Date().toLocaleDateString(), 450, 50);

  let y = 110;
  generateIndividualResultsHeader(doc, y);

  walkers.forEach((walker) => {
    const name = doc.splitTextToSize(
      `${walker.firstName} ${walker.lastName}`,
      115
    ) as string[];
    const group = doc.splitTextToSize(walker.group, 125) as string[];

    const numberOfLines = Math.max(name.length, group.length);

    const endY = y + numberOfLines * 14 + 10;

    if (endY > 820) {
      doc.addPage();
      y = 30;
      generateIndividualResultsHeader(doc, y);
    }

    generateHr(doc, (y += 7), 1);

    doc.setTextColor("black");
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    y += 5;

    generateRow(doc, 50, (y += 14), [
      {
        data: `${getPosition(walker, walkers)}`,
        width: 40,
      },
      {
        data: `${walker.walkerNumber}`,
        width: 45,
      },
      {
        data: name,
        width: 120,
      },
      {
        data: `${walker.age}`,
        width: 20,
      },
      {
        data: group,
        width: 130,
      },
      {
        data: `${walker.startTime}`,
        width: 45,
      },
      {
        data: `${walker.finishTime}`,
        width: 45,
      },
      {
        data: `${walker.totalTime}`,
        width: 45,
      },
    ]);

    y += (numberOfLines - 1) * 14;
  });

  doc.save(`${route} Individual Result Report.pdf`);
}

function generateIndividualResultsHeader(doc: jsPDF, y: number) {
  doc.setFontSize(8);
  doc.setFont("helvetica", "bold");
  generateRow(doc, 50, y, [
    {
      data: "Position",
      width: 40,
    },
    {
      data: "Walker No.",
      width: 45,
    },
    {
      data: "Walker",
      width: 120,
    },
    {
      data: "Age",
      width: 20,
    },
    {
      data: "Group",
      width: 130,
    },
    {
      data: "Start Time",
      width: 45,
    },
    {
      data: "Finish Time",
      width: 45,
    },
    {
      data: "Walk Time",
      width: 45,
    },
  ]);
}
interface HasTime {
  totalTime: string;
}

function getPosition(item: HasTime, list: HasTime[]): number {
  const positions = list
    .map((v: HasTime, i: number) => {
      return { item: v, position: i };
    })
    .filter((v) => v.item.totalTime === item.totalTime)
    .map((v) => v.position);
  return Math.min(...positions) + 1;
}

function generateHeader(doc: jsPDF, y: number) {
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  generateRow(doc, 50, y, [
    {
      data: "No.",
      width: 25,
    },
    {
      data: "Name",
      width: 100,
    },
    {
      data: "Age",
      width: 30,
    },
    {
      data: "Group",
      width: 150,
    },
    {
      data: "Declared Medical Details",
      width: 295,
    },
  ]);
  generateHr(doc, y + 5, 3);
}

function generateStaffHeader(doc: jsPDF, y: number) {
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  generateRow(doc, 50, y, [
    {
      data: "Name",
      width: 150,
    },
    {
      data: "Team",
      width: 130,
    },
    {
      data: "Declared Medical Details",
      width: 230,
    },
  ]);
  generateHr(doc, y + 5, 3);
}

function generateRow(
  doc: jsPDF,
  x: number,
  y: number,
  row: { data: string | string[]; width: number }[]
) {
  row.forEach(({ data, width }) => {
    doc.text(data, x, y);
    x += width;
  });
}

function generateHr(doc: jsPDF, y: number, thickness: number) {
  doc.setLineWidth(thickness);
  doc.line(50, y, 550, y);
}
