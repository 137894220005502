
/* eslint-disable no-undef */
import Vue from "vue";
import moment from "moment";
import VirtualForm from "./VirtualForm.vue";
import ActivityLog from "./ActivityLog.vue";
import {
  Activity,
  ActivityType,
  UserProfile,
  VForm,
  VirtualWalker,
} from "@/types";
import { VirtualRoute as Route, Section, Unit } from "@/types/enums";
import { getRouteColour, getSectionColour } from "@/utils/colours";
import { getVirtualRouteLength } from "@/utils/route";
import {
  createMultipleVirtualCertificates,
  createVirtualCertificate,
} from "@/utils/certificate";
import { functions } from "@/plugins/firebaseConfig";

interface TableWalker {
  name: string;
  route: Route;
  section: Section;
  group: string;
  distance: string;
  percentage: string;
  contact: string;
  id: string;
}

enum WalkingStatus {
  NOT_STARTED = "Not Started",
  STARTED = "Started",
  FINISHED = "Finished",
}

export default Vue.extend({
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Route",
          value: "route",
        },
        {
          text: "Section",
          value: "section",
        },
        {
          text: "Group",
          value: "group",
        },
        {
          text: "Distance",
          value: "distance",
        },
        {
          text: "Percentage Complete",
          value: "percentage",
        },
        {
          text: "Group Contact",
          value: "contact",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      date: new Date().toISOString().substr(0, 10),
      activity: {
        date: new Date().toISOString().substr(0, 10),
        distance: 0,
        method: {} as ActivityType,
      } as Activity,
      activityForm: false,
      walkerForm: false,
      deleteDialog: false,
      currentWalker: {} as VirtualWalker,
      search: "",
      helpDialog: false,
      filterDialog: false,
      activityLogDialog: false,
      routeCompleteDialog: false,
      downloadDialog: false,
      linkWalkerDialog: false,
      walkerNumber: "0",
      confirmLinkWalkerDialog: false,
      walkerName: "",
      virtualWalkerName: "",
      entriesClosedDialog: false,
      filters: {
        route: ["Bronze", "Silver", "Gold", "Platinum"],
        status: ["Not Started", "Started", "Finished"],
        section: [
          "Squirrels",
          "Beavers",
          "Cubs",
          "Scouts",
          "Explorers",
          "Rainbows",
          "Brownies",
          "Guides",
          "Rangers",
          "Adults",
          "Siblings",
        ].sort(),
      },
      filteredRoutes: [0, 1, 2],
      filteredStatuses: [0, 1, 2],
      filteredSections: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      activityTypes: [
        {
          name: "Walking",
          units: Unit.MILES,
          multiplier: 1,
        },
        {
          name: "Running",
          units: Unit.MILES,
          multiplier: 1,
        },
        {
          name: "Cycling",
          units: Unit.MILES,
          multiplier: 1 / 3,
        },
        // {
        //   name: "Mountain Biking",
        //   units: "miles",
        //   multiplier: 1 / 3,
        // },
        {
          name: "Spinning",
          units: Unit.MINUTES,
          multiplier: 1 / 15,
        },
        {
          name: "Aerobics",
          units: Unit.MINUTES,
          multiplier: 1 / 20,
        },
        {
          name: "Stairmaster",
          units: Unit.MINUTES,
          multiplier: 1 / 15,
        },
        {
          name: "Roller Blading",
          units: Unit.MILES,
          multiplier: 1 / 3,
        },
        {
          name: "Swimming",
          units: Unit.MILES,
          multiplier: 1,
        },
        // {
        //   name: "Volleyball",
        //   units: "mins",
        //   multiplier: 1 / 45,
        // },
        {
          name: "Gardening",
          units: Unit.MINUTES,
          multiplier: 1 / 30,
        },
        // {
        //   name: "Dancing",
        //   units: "mins",
        //   multiplier: 1 / 30,
        // },
        {
          name: "Raking Leaves",
          units: Unit.MINUTES,
          multiplier: 1 / 30,
        },
        // {
        //   name: "Water Aerobics",
        //   units: "mins",
        //   multiplier: 1 / 20,
        // },
        {
          name: "Wheeling self in wheelchair",
          units: Unit.MINUTES,
          multiplier: 1 / 30,
        },
        // {
        //   name: "Wheelchair Basketball",
        //   units: "mins",
        //   multiplier: 1 / 20,
        // },
        {
          name: "Stair Walking",
          units: Unit.MINUTES,
          multiplier: 1 / 20,
        },
        {
          name: "Horse Riding",
          units: Unit.MILES,
          multiplier: 1,
        },
        {
          name: "Yoga",
          units: Unit.MINUTES,
          multiplier: 1 / 30,
        },
        {
          name: "Strength Training",
          units: Unit.MINUTES,
          multiplier: 1 / 30,
        },
        // {
        //   name: "Basketball",
        //   units: "mins",
        //   multiplier: 1 / 20,
        // },
        {
          name: "Skiing",
          units: Unit.MINUTES,
          multiplier: 1 / 35,
        },
      ] as ActivityType[],
      dateMenu: false,
      showStarterGuide: false,
      Route,
    };
  },
  computed: {
    walkers(): VirtualWalker[] {
      let walkerArray = this.$store.state.virtual
        .virtualWalkers as VirtualWalker[];
      let routeArray = this.filteredRoutes.map((v) => this.filters.route[v]);
      let statusArray = this.filteredStatuses.map(
        (v) => this.filters.status[v]
      );
      let sectionArray = this.filteredSections.map(
        (v) => this.filters.section[v]
      );
      walkerArray = walkerArray.filter((w) => {
        const percentage = parseFloat(this.percentageDistance(w));
        const status =
          percentage >= 100
            ? "Finished"
            : percentage == 0
            ? "Not Started"
            : "Started";
        return (
          routeArray.includes(w.route) &&
          statusArray.includes(status) &&
          sectionArray.includes(w.section)
        );
      });
      return walkerArray;
    },
    tableWalkers(): TableWalker[] {
      return this.walkers.map((w) => {
        return {
          name: w.firstName + " " + w.lastName,
          route: w.route,
          section: w.section,
          group: w.group ?? this.getContact(w).group,
          distance: this.totalDistance(w).toFixed(2),
          percentage: this.percentageDistance(w),
          contact:
            this.getContact(w).firstName + " " + this.getContact(w).lastName,
          id: w.id,
          linked: w.linked,
        };
      });
    },
    entriesOpened(): boolean {
      return (
        this.$store.getters["info/virtualEventStarted"] ||
        this.$store.getters["user/isAdmin"]
      );
    },
    entriesClosed(): boolean {
      return (
        this.$store.getters["info/virtualEventEnded"] &&
        !this.$store.getters["user/isAdmin"]
      );
    },
    formRef(): VForm {
      return this.$refs.activityForm as VForm;
    },
    isAdmin(): boolean {
      return this.$store.getters["user/isAdmin"];
    },
    virtualEventStartDate(): Date {
      const date = (
        this.$store.state.info.dates.virtual
          .start as firebase.default.firestore.Timestamp
      ).toDate();
      return date;
    },
    virtualEventEndDate(): Date {
      const date = (
        this.$store.state.info.dates.virtual
          .end as firebase.default.firestore.Timestamp
      ).toDate();
      return date;
    },
  },
  watch: {
    walkers() {
      this.showStarterGuide =
        this.$store.state.virtual.virtualWalkers < 1 &&
        this.$store.getters.loaded;
    },
    date() {
      this.activity.date = this.formatDate(this.date);
    },
  },
  methods: {
    closeForm() {
      this.walkerForm = false;
    },
    newWalker() {
      if (this.entriesClosed) {
        this.entriesClosedDialog = true;
      } else {
        this.currentWalker = {} as VirtualWalker;
        this.walkerForm = true;
      }
    },
    getWalker(id: string): VirtualWalker {
      return this.$store.getters["virtual/getWalker"](id);
    },
    openActivityForm(walker: VirtualWalker) {
      if (this.entriesClosed || !this.entriesOpened) {
        this.entriesClosedDialog = true;
      } else {
        this.currentWalker = this.getWalker(walker.id);
        this.activityForm = true;
      }
    },
    editWalker(walker: VirtualWalker) {
      if (this.entriesClosed) {
        this.entriesClosedDialog = true;
      } else {
        this.currentWalker = this.getWalker(walker.id);
        this.walkerForm = true;
      }
    },
    deleteWalker(walker: VirtualWalker) {
      if (this.entriesClosed) {
        this.entriesClosedDialog = true;
      } else {
        this.currentWalker = this.getWalker(walker.id);
        this.deleteDialog = true;
      }
    },
    logActivity() {
      if (!this.formRef.validate()) {
        return;
      }
      const adjustedDistance = this.calculateDistance(
        this.activity.distance,
        this.activity.method
      );
      let [day, month, year] = this.activity.date.split("/");
      this.activity.date = `${year}-${month}-${day}`;

      const bumpFlag =
        this.totalDistance(this.currentWalker) <
        this.routeDistance(this.currentWalker.route);
      if (this.currentWalker.activityLog) {
        this.currentWalker.activityLog.push({
          ...this.activity,
          distance: adjustedDistance,
        });
      } else {
        this.$set(this.currentWalker, "activityLog", [
          {
            ...this.activity,
            distance: adjustedDistance,
          },
        ]);
      }

      if (this.currentWalker.totalMiles) {
        this.currentWalker.totalMiles += adjustedDistance;
      } else {
        this.currentWalker.totalMiles = adjustedDistance;
      }

      this.$store
        .dispatch("virtual/updateVirtualWalker", this.currentWalker)
        .then(() => {
          this.activityForm = false;
          if (
            bumpFlag &&
            this.totalDistance(this.currentWalker) >=
              this.routeDistance(this.currentWalker.route)
          ) {
            this.routeCompleteDialog = true;
          } else {
            this.clearData();
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    confirmDelete() {
      this.$store
        .dispatch("virtual/deleteWalker", this.currentWalker)
        .then(() => {
          this.deleteDialog = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    getColour(route: Route): string {
      return getRouteColour(route);
    },
    downloadWalkers() {
      const csvString = this.makeCsv(this.walkers);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "walkers.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeCsv(data: VirtualWalker[]) {
      const header =
        "First Name,Last Name,Route,Section,Group,Group Contact,Email,Distance,Percentage";
      const values = data
        .map((o) => {
          const contact = this.getContact(o);
          return [
            `"${o.firstName}"`,
            `"${o.lastName}"`,
            `"${o.route}"`,
            `"${o.section}"`,
            `"${contact.group}"`,
            `"${contact.firstName + " " + contact.lastName}"`,
            `"${contact.email}"`,
            `"${this.totalDistance(o)}"`,
            `"${this.percentageDistance(o)}"`,
          ].join(",");
        })
        .join("\n");
      return "data:text/csv;charset=utf-8," + header + "\n" + values;
    },
    downloadHTML() {
      const sortedWalkers = this.walkers.sort((a, b) => {
        const aGroup = this.getContact(a).group;
        const bGroup = this.getContact(b).group;
        if (!aGroup) return 1;
        else if (!bGroup) return -1;
        else if (aGroup.toLowerCase() > bGroup.toLowerCase()) return 1;
        else if (aGroup.toLowerCase() < bGroup.toLowerCase()) return -1;
        else return 0;
      });
      const csvString = this.makeHTML(sortedWalkers);
      const encodedUri = encodeURI(csvString);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "walkers.html");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    makeHTML(data: VirtualWalker[]) {
      const header = `
        <html>
          <style>
            table {
              margin-left: auto;
              margin-right: auto;
            }
            .section,
            .distance {
              padding-right: 5px;
            }
            th,
            td {
              padding-left: 5px;
              padding-right: 40px;
            }
            table,
            th,
            td {
              border: 1px solid grey;
              border-collapse: collapse;
            }
          </style>
          <body>
            <table>
              <tr>
                <th>Section</th>
                <th>Route</th>
                <th>Group</th>
                <th>Distance</th>
              </tr>`;
      const content = data
        .map((o) => {
          const contact = this.getContact(o);
          return `
              <tr>
                <td class="section">${o.section}</td>
                <td>${o.route}</td>
                <td>${contact.group}</td>
                <td class="distance">${this.totalDistance(o).toFixed(2)}</td>
              </tr>`;
        })
        .join("");
      const footer = `
            </table>
          </body>
        </html>`;
      return "data:text/html;charset=utf-8," + header + content + footer;
    },
    resetFilters() {
      this.filteredRoutes = [0, 1, 2, 3];
      this.filteredStatuses = [0, 1, 2];
      this.filteredSections = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    },
    clearData() {
      this.activity = {
        date: this.formatDate(new Date().toISOString().substr(0, 10)),
        distance: 0,
        method: {} as ActivityType,
      };
      this.currentWalker = {} as VirtualWalker;
    },
    totalDistance(walker: VirtualWalker): number {
      return this.$store.getters["virtual/totalDistance"](walker.id);
    },
    percentageDistance(walker: VirtualWalker): string {
      return (
        (this.totalDistance(walker) / this.routeDistance(walker.route)) *
        100
      ).toFixed(2);
    },
    routeDistance(route: Route): number {
      return getVirtualRouteLength(route);
    },
    openActivityLog(walker: VirtualWalker) {
      this.currentWalker = this.getWalker(walker.id);
      this.activityLogDialog = true;
    },
    calculateDistance(amount: number, method: ActivityType): number {
      const distance = method.multiplier * amount;
      return distance ? distance : 0;
    },
    nextRoute(walker: VirtualWalker): Route {
      switch (walker.route) {
        case Route.BRONZE:
          return Route.SILVER;
        case Route.SILVER:
          return Route.GOLD;
        case Route.GOLD:
          return Route.PLATINUM;
        default:
          return Route.NONE;
      }
    },
    bumpRoute(walker: VirtualWalker) {
      walker.route = this.nextRoute(walker);
      this.$store
        .dispatch("virtual/updateVirtualWalker", walker)
        .then(() => {
          this.routeCompleteDialog = false;
          this.clearData();
        })
        .catch((err) => {
          throw err;
        });
    },
    getSectionColour(section: Section) {
      return getSectionColour(section);
    },
    getContact(walker: VirtualWalker): UserProfile {
      if (this.$store.getters["user/isAdmin"]) {
        return this.$store.getters["admin/getUser"](walker.contact);
      } else {
        return this.$store.state.user.profile;
      }
    },
    dateIsSameOrAfter(d1: string, d2: string): boolean {
      if (/\d{2}\/\d{2}\/\d{4}/.test(d1)) {
        const date = d1.split("/");
        return moment(`${date[2]}-${date[1]}-${date[0]}`).isSameOrAfter(d2);
      } else {
        return false;
      }
    },
    dateIsSameOrBefore(d1: string, d2: string): boolean {
      if (/\d{2}\/\d{2}\/\d{4}/.test(d1)) {
        const date = d1.split("/");
        return moment(`${date[2]}-${date[1]}-${date[0]}`).isSameOrBefore(d2);
      } else {
        return false;
      }
    },
    formatDate(date: string): string {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date: string) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    generateCertificate(id: string) {
      const walker = this.getWalker(id);
      createVirtualCertificate(walker);
    },
    generateCertificates() {
      createMultipleVirtualCertificates(this.walkers);
    },
    getRuleDateString(date: Date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getReadableDateString(date: Date) {
      return moment(date).format("DD/MM/YYYY");
    },
    openLinkWalkerDialog(walker: TableWalker) {
      this.currentWalker = this.getWalker(walker.id);
      this.linkWalkerDialog = true;
    },
    checkWalkersMatchAndLink() {
      const checkWalkersMatch = functions.httpsCallable("checkWalkersMatch");
      const walkerNumber = parseInt(this.walkerNumber);

      this.$store.commit("startLoading", "functions/checkWalkersMatch");
      checkWalkersMatch({
        virtualWalker: this.currentWalker,
        walkerNumber: walkerNumber,
      })
        .then((result) => {
          const data = result.data as {
            walkerName: string;
            virtualWalkerName: string;
            walkersMatch: boolean;
          };
          if (data.walkersMatch) {
            this.linkWalkers(this.currentWalker, walkerNumber);
            this.$store.commit("finishLoading", "functions/checkWalkersMatch");
          } else {
            this.$store.commit("finishLoading", "functions/checkWalkersMatch");
            if (this.isAdmin) {
              this.walkerName = data.walkerName;
              this.virtualWalkerName = data.virtualWalkerName;
            }
            this.linkWalkerDialog = false;
            this.confirmLinkWalkerDialog = true;
          }
        })
        .catch((err) => {
          this.$store.commit("setErrorMessage", err.toString());
          this.$store.commit("finishLoading", "functions/checkWalkersMatch");
        });
    },
    linkWalkers(virtualWalker: VirtualWalker, walkerNumber: number) {
      this.$store.commit("startLoading", "functions/getWalkerDistance");
      const getDistance = functions.httpsCallable("getWalkerDistance");
      getDistance(walkerNumber)
        .then((result) => {
          const distance = result.data;
          const eventDate = this.$store.state.info.dates.main
            .event as firebase.default.firestore.Timestamp;

          virtualWalker.linked = true;
          virtualWalker.linkedWalkerNumber = walkerNumber;
          const activity = {
            date: moment(eventDate.toDate()).format("YYYY-MM-DD"),
            distance,
            method: this.activityTypes[0],
          };

          const bumpFlag =
            this.totalDistance(this.currentWalker) <
            this.routeDistance(this.currentWalker.route);
          if (this.currentWalker.activityLog) {
            this.currentWalker.activityLog.push(activity);
          } else {
            this.$set(this.currentWalker, "activityLog", [activity]);
          }

          if (this.currentWalker.totalMiles) {
            this.currentWalker.totalMiles += activity.distance;
          } else {
            this.currentWalker.totalMiles = activity.distance;
          }

          this.$store
            .dispatch("virtual/updateVirtualWalker", this.currentWalker)
            .then(() => {
              this.$store.commit(
                "finishLoading",
                "functions/getWalkerDistance"
              );
              this.linkWalkerDialog = false;
              this.confirmLinkWalkerDialog = false;
              if (
                bumpFlag &&
                this.totalDistance(this.currentWalker) >=
                  this.routeDistance(this.currentWalker.route)
              ) {
                this.routeCompleteDialog = true;
              }
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          this.$store.commit("setErrorMessage", err.toString());
          this.$store.commit("finishLoading", "functions/getWalkerDistance");
        });
    },
  },
  created() {
    const routeArray = this.$route.query.route
      ? (JSON.parse(this.$route.query.route as string) as Route[])
      : Object.values(Route);
    this.filteredRoutes = routeArray.map((v) => this.filters.route.indexOf(v));
    const statusArray = this.$route.query.status
      ? (JSON.parse(this.$route.query.status as string) as WalkingStatus[])
      : Object.values(WalkingStatus);
    this.filteredStatuses = statusArray.map((v) =>
      this.filters.status.indexOf(v)
    );
    const sectionArray = this.$route.query.section
      ? (JSON.parse(this.$route.query.section as string) as Section[])
      : Object.values(Section).sort();
    this.filteredSections = sectionArray.map((v) =>
      this.filters.section.indexOf(v)
    );
    this.activity.date = this.formatDate(this.activity.date);
  },
  components: {
    VirtualForm,
    ActivityLog,
  },
});
